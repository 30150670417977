import React from "react";
import Hero from "../components/common/hero";
import { graphql } from "gatsby";
import useTranslations from "../components/useTranslations";
import HelmetHeader from "../components/helmetHeader";
import { HiCheck } from "react-icons/hi";

const Partners = ({ location, data: { page } }) => {
  const { lang } = useTranslations();
  return (
    <>
      <HelmetHeader
        location={location}
        lang={lang}
        title={page.seo.metaTitle}
        metas={[{ name: "description", content: page.seo.metaDescription }]}
      />
      <Hero title={page.header.title} subtitle={page.header.subtitle} />
      <div className="w-full bg-gray-50 overflow-hidden">
        <div className="bg-white w-full">
          <div className="mx-auto max-w-5xl py-20 px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <p className="text-3xl font-bold tracking-tight text-gray-900 leading-8">
                {page.features[0]?.title}
              </p>
            </div>

            <div className="mt-10">
              <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
                {page.features[0]?.bulletPoint.map((feature) => (
                  <div key={feature.title} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-cyan-600 text-white">
                        <div
                          dangerouslySetInnerHTML={{ __html: feature.icon }}
                        />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                        {feature.title}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        <div className="relative mt-24">
          <div className="lg:mx-auto lg:grid lg:max-w-5xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
              <div>
                <div>
                  <span className="flex h-12 w-12 items-center justify-center rounded-md text-white bg-cyan-600">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page.features[1]?.icon,
                      }}
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                    {page.features[1]?.title}
                  </h2>
                  <div className="mt-6">
                    {page.features[1]?.bulletPoint?.map((feature) => (
                      <dt className="mt-2">
                        <HiCheck
                          className="absolute h-6 w-6 text-green-500"
                          aria-hidden="true"
                        />
                        <p className="ml-9 text-lg leading-6 text-gray-500">
                          {feature.title}
                        </p>
                      </dt>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={page.features[1]?.illustration?.url}
                  alt={page.features[1]?.illustration?.alternativeText}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="my-24">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
              <div>
                <div>
                  <span className="flex h-12 w-12 items-center justify-center rounded-md text-white bg-cyan-600">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page.features[2]?.icon,
                      }}
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                    {page.features[2]?.title}
                  </h2>
                  <div className="mt-6">
                    {page.features[2]?.bulletPoint?.map((feature) => (
                      <dt className="mt-2">
                        <HiCheck
                          className="absolute h-6 w-6 text-green-500"
                          aria-hidden="true"
                        />
                        <p className="ml-9 text-lg leading-6 text-gray-500">
                          {feature.title}
                        </p>
                      </dt>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
              <div className="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={page.features[2]?.illustration?.url}
                  alt={page.features[2]?.illustration?.alternativeText}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 pb-20">
          <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 leading-8">
                {page.features[3]?.title}
              </p>
            </div>

            <div className="mt-10">
              <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
                {page.features[3]?.bulletPoint.map((feature) => (
                  <div key={feature.title} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-cyan-600 text-white">
                        <div
                          dangerouslySetInnerHTML={{ __html: feature.icon }}
                        />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                        {feature.title}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        <div className="bg-cyan-700">
          <div className="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              <span className="block">{page.cta?.title}</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-cyan-200">
              {page.cta?.description}
            </p>
            <a
              href={page.cta?.link?.href}
              target={page.cta?.link?.target}
              className="cursor-pointer mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-cyan-600 hover:bg-cyan-50 sm:w-auto"
            >
              {page.cta?.link?.label}
            </a>
          </div>
        </div>

        <div className="bg-gray-50 py-16 sm:py-24">
          <div className="max-w-6xl mx-auto px-8 lg:px-16">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2 className="text-3xl font-extrabold text-gray-900">
                  {page.faqs?.title}
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  {page.faqs?.subtitle}
                </p>
              </div>
              <div className="mt-12 lg:mt-0 lg:col-span-2">
                <dl className="space-y-12">
                  {page.faqs?.questionsAnswers.map((item, index) => (
                    <div key={index}>
                      <dt className="text-lg leading-6 font-medium text-gray-900">
                        {item.question}
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        {item.answer}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query StrapiChannelPartnerPage($locale: String) {
    page: strapiChannelPartnerPage(locale: { eq: $locale }) {
      header {
        subtitle
        title
      }
      seo {
        metaTitle
        metaDescription
      }
      features {
        title
        icon
        illustration {
          url
          alternativeText
        }
        bulletPoint {
          title
          description
          icon
        }
      }
      cta {
        title
        description
        link {
          href
          label
          target
        }
      }
      faqs {
        title
        subtitle
        questionsAnswers {
          question
          answer
        }
      }
    }
  }
`;

export default Partners;
